@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url("./components/Toolbar/test_img.png");
  background-repeat: repeat;
  background-size: cover; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .App{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  // This makes the div take up the full viewport height 

} */

.App {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}

:root {
  --dark-purple-50-text: #868a97;
  --dark-purple: #0d162f;
  --midnight-blue: var(--dark-purple);
  --purple-bg: var(--midnight-blue);
  --ghost-purple-bg: #eef2ff;
  --extra-light-bg: #f6f5f9;
  --borders: #e6e7ea;
  --borders-dark: #cfd0d5;
  --dark-purple-5-opacity: rgba(13, 22, 47, 0.05);
  --dark-purple-10-opacity: rgba(13, 22, 47, 0.1);
  --white: white;
  --color: transparent;
  --section-padding-x: 20px;
  --indigo: #6056ff;
  --dark-purple-30: #b6b9c0;
  --body-bg: #f9f9f9;
  --dark-indigio: #3b32f9;
  --indigo-40-opacity: rgba(96, 86, 255, 0.4);
  --indigio-25-opacity: rgba(96, 86, 255, 0.25);
  --dark-indigo-50-opacity: rgba(59, 50, 249, 0.5);
  --white-20-opacity: rgba(255, 255, 255, 0.2);
  --purple: #943bff;
  --indigo-hover: #7281ff;
  --indigo-menu-hover: #e6e9ff;
  --dark-purple-5: #f3f3f4;
  --pill-bg: #f9f9fb;
  --secondary-hover: #f9f9fa;
  --icon-secondary: #707287;
  --white-50-opacity: rgba(255, 255, 255, 0.5);
  --indigo-10-opacity: rgba(96, 86, 255, 0.1);
  --display-shadow-50: rgba(22, 19, 74, 0.5);
  --display-shadow-5-2: rgba(22, 19, 74, 0.05);
  --section-width: 1268px;
  --lime: #defd5e;
  --yellow: #f9b934;
  --white-bg: #f7faff;
  --Goldenrod: rgb(249, 185, 52);
  --Goldenrod-hover: rgb(229, 165, 32);
  --red: rgb(239, 83, 80);
  --green: green;
  --light-green: #32bc5c1a;
  --error-bg: rgba(255 86 48/ 0.16);
  --error-main: #b71d18;
  /* Dashboard specific color  */
  --palette-mode: light;
  --palette-primary-lighter: #c8fad6;
  --palette-green-light: #5be49b;
  --palette-green-main: #00a76f;
  --palette-green-dark: #007867;
  --palette-green-darker: #004b50;

  --palette-purple-lighter: #efd6ff;
  --palette-purple-light: #c684ff;
  --palette-purple-main: #8e33ff;

  --palette-info-lighter: #cafdf5;
  --palette-info-light: #61f3f3;
  --palette-info-main: #00b8d9;
  --palette-info-dark: #006c9c;

  --palette-success-contrastText: #ffffff;
  --palette-warning-lighter: #fff5cc;
  --palette-warning-light: #ffd666;
  --palette-warning-main: #ffab00;
  --palette-warning-dark: #b76e00;
  --palette-warning-darker: #7a4100;
  --palette-error-lighter: #ffe9d5;
  --palette-error-light: #ffac82;
  --palette-error-main: #ff5630;
  --palette-error-dark: #b71d18;
  --palette-error-darker: #7a0916;
  --palette-spring-green: #00ff91;

  --palette-dark-indigo: #0f1141; /* First color */
  --palette-indigo: #6056ff; /* Second color */


  --custom-gradient: linear-gradient(135deg, #080a2b 20%, #4f46e5 80%);




  /*
  current one 
  --custom-gradient: linear-gradient(
    135deg,
    var(--palette-dark-indigo) 30%,
    var(--palette-indigo) 70%
  ); */
}

.theme-gradient-background {
  background: linear-gradient(135deg, #f0f2ff 0%, #e6e9ff 50%, #f5f7ff 100%);
}

/* AI Rippler Vertical Scroll Background - Optimized for long pages */

/* AI Rippler Vertical Scroll Background - Optimized for long pages */

/* Main background container - add this to your page container */
.ai-background {
  position: relative;
  background: linear-gradient(135deg, rgba(96, 86, 255, 0.03) 0%, rgba(142, 51, 255, 0.02) 100%);
  overflow: hidden;
  min-height: 100vh; /* Ensure it covers at least the viewport */
}

/* Fixed Background Elements - These stay in place during scroll */
.bg-fixed-elements {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  pointer-events: none;
  opacity: 0.7; /* Global opacity for fixed elements */
}

/* Grid pattern that covers the entire viewport */
.bg-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
      radial-gradient(circle, var(--dark-purple-10-opacity) 1px, transparent 1px),
      radial-gradient(circle, var(--dark-purple-10-opacity) 1px, transparent 1px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  opacity: 0.5;
}

/* Scroll-based Background Elements - These are positioned throughout the page */
.bg-scroll-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

/* Top section decorative elements */
.top-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* Middle section decorative elements */
.middle-decoration {
  position: absolute;
  top: 100vh;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* Bottom section decorative elements */
.bottom-decoration {
  position: absolute;
  top: 200vh;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* General decoration for additional sections */
.additional-decoration {
  position: absolute;
  top: 300vh;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* Circle elements - positioned strategically throughout the page */
.circle {
  position: absolute;
  border-radius: 50%;
}

.circle-1 {
  width: 300px;
  height: 300px;
  background: linear-gradient(135deg, var(--palette-indigo), transparent);
  top: 5vh;
  left: -100px;
  opacity: 0.2;
}

.circle-2 {
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, var(--palette-purple-main), transparent);
  top: 70vh;
  right: -50px;
  opacity: 0.15;
}

.circle-3 {
  width: 400px;
  height: 400px;
  background: linear-gradient(135deg, var(--palette-info-main), transparent);
  top: 150vh;
  left: -200px;
  opacity: 0.08;
}

.circle-4 {
  width: 250px;
  height: 250px;
  background: linear-gradient(135deg, var(--palette-purple-main), transparent);
  top: 220vh;
  right: -100px;
  opacity: 0.1;
}

.circle-5 {
  width: 350px;
  height: 350px;
  background: linear-gradient(135deg, var(--palette-indigo), transparent);
  top: 320vh;
  left: -150px;
  opacity: 0.12;
}

/* Node elements - appearing in different sections */
.nodes {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.node {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--palette-indigo);
  border-radius: 50%;
  opacity: 0.4;
  box-shadow: 0 0 8px rgba(96, 86, 255, 0.3);
}

/* Top section nodes */
.top-nodes .node-1 { top: 20vh; left: 20%; }
.top-nodes .node-2 { top: 30vh; left: 60%; }
.top-nodes .node-3 { top: 70vh; left: 35%; }
.top-nodes .node-4 { top: 45vh; left: 80%; }

/* Middle section nodes */
.middle-nodes .node-1 { top: 15vh; left: 30%; }
.middle-nodes .node-2 { top: 40vh; left: 70%; }
.middle-nodes .node-3 { top: 65vh; left: 25%; }
.middle-nodes .node-4 { top: 80vh; left: 60%; }

/* Bottom section nodes */
.bottom-nodes .node-1 { top: 20vh; left: 15%; }
.bottom-nodes .node-2 { top: 50vh; left: 75%; }
.bottom-nodes .node-3 { top: 70vh; left: 40%; }

/* Connections between nodes - different in each section */
.connection {
  position: absolute;
  background-color: var(--palette-indigo);
  height: 2px;
  transform-origin: left center;
  opacity: 0.25;
}

/* Top section connections */
.top-nodes .connection-1 {
  width: 35%;
  top: calc(20vh + 4px);
  left: calc(20% + 4px);
  transform: rotate(15deg);
}

.top-nodes .connection-2 {
  width: 30%;
  top: calc(30vh + 4px);
  left: calc(60% + 4px);
  transform: rotate(-25deg);
}

/* Middle section connections */
.middle-nodes .connection-1 {
  width: 40%;
  top: calc(15vh + 4px);
  left: calc(30% + 4px);
  transform: rotate(20deg);
}

.middle-nodes .connection-2 {
  width: 25%;
  top: calc(65vh + 4px);
  left: calc(25% + 4px);
  transform: rotate(35deg);
}

/* Bottom section connections */
.bottom-nodes .connection-1 {
  width: 45%;
  top: calc(20vh + 4px);
  left: calc(15% + 4px);
  transform: rotate(30deg);
}

.bottom-nodes .connection-2 {
  width: 30%;
  top: calc(50vh + 4px);
  left: calc(75% + 4px);
  transform: rotate(-15deg);
}

/* SVG Particles - distributed throughout the page */
.particle {
  position: absolute;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.15;
}

/* Top section particles */
.top-decoration .particle-brain {
  top: 15vh;
  left: 10%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236056ff"><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"/></svg>');
}

.top-decoration .particle-chip {
  top: 65vh;
  right: 15%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%238e33ff"><path d="M6 4h12v1H6zm12 3H6v10h12zM6 20h12v1H6zM4 7v10H3V7zm18 10V7h-1v10zM19 6h1v1h-1zm1 11h-1v1h1zM4 17H3v1h1zm0-11h1V5H4z"/></svg>');
}

/* Middle section particles */
.middle-decoration .particle-network {
  top: 25vh;
  right: 20%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236056ff"><path d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm10 6c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6z"/></svg>');
}

.middle-decoration .particle-data {
  top: 75vh;
  left: 15%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236056ff"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 14H5v-2h7v2zm7-4H5v-2h14v2zm0-4H5V7h14v2z"/></svg>');
}

/* Bottom section particles */
.bottom-decoration .particle-code {
  top: 30vh;
  left: 20%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%238e33ff"><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>');
}

.bottom-decoration .particle-cloud {
  top: 60vh;
  right: 10%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236056ff"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"/></svg>');
}

/* Floating animation for particles */
@keyframes float {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
  100% {
      transform: translateY(0);
  }
}

.particle-brain, .particle-cloud {
  animation: float 8s ease-in-out infinite;
}

.particle-chip, .particle-data {
  animation: float 9s ease-in-out infinite 1s;
}

.particle-network, .particle-code {
  animation: float 10s ease-in-out infinite 2s;
}

/* Pulsing effect for nodes */
@keyframes pulse-node {
  0% {
      transform: scale(1);
      opacity: 0.4;
  }
  50% {
      transform: scale(1.3);
      opacity: 0.2;
  }
  100% {
      transform: scale(1);
      opacity: 0.4;
  }
}

.node-1 { animation: pulse-node 4s infinite; }
.node-3 { animation: pulse-node 5s infinite 1s; }

/* Subtle gradient overlay at section boundaries to create visual separation */
.section-boundary {
  position: absolute;
  height: 20vh;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  opacity: 0.3;
}

.top-boundary {
  top: 80vh;
  background: linear-gradient(to bottom, transparent, rgba(96, 86, 255, 0.05));
}

.middle-boundary {
  top: 180vh;
  background: linear-gradient(to bottom, transparent, rgba(142, 51, 255, 0.05));
}

.bottom-boundary {
  top: 280vh;
  background: linear-gradient(to bottom, transparent, rgba(96, 86, 255, 0.05));
}

/* Add this class to your content containers to ensure they appear above the background */
.content-above-bg {
  position: relative;
  z-index: 1;
}