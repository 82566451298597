/* Button.css */

/* button-primary-nav is for reference */
.button-primary-nav {
  border: 1px solid var(--dark-indigio);
  background-color: var(--indigo);
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple);
  white-space: pre;
  border-radius: 8px;
  flex-flow: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  min-height: 36px;
  max-height: 36px;
  padding: 16px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.button-outlined {
  border: 1px solid var(--dark-indigio) !important;
  cursor: pointer;
  border-radius: "14px" !important;
  color: var(--indigo) !important;
}

.button {
  background-color: var(--indigo) !important;
  border: 1px solid var(--dark-indigio) !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple) !important;
  font-weight: 500;
}

.button-white {
  background-color: white !important;
  border: 1px solid var(--dark-indigio) !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: 500 !important;
  color: var(
    --indigo
  ) !important; /* Ensure text color contrasts with white background */
}

.button-white:hover {
  border: 1px solid var(--dark-indigio) !important;
  background-color: var(--indigo) !important;
  color: white !important;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple) !important;
}

.button-animation {
  background-color: var(--indigo) !important;
  border: 1px solid var(--dark-indigio) !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple) !important;
  font-weight: 500;
}
.button-animation-hover {
  border: 1px solid var(--dark-indigio) !important;
  background-color: white !important;
  padding: 10px 20px !important;
  cursor: pointer;
  font-weight: 500;
  color: var(
    --indigo
  ) !important; /* Ensure text color contrasts with white background */
}

.fade-element {
  opacity: 1; /* Initial opacity set to 1 */
  transition: opacity 0.1s ease-in-out; /* Smooth transition */
}

.button:hover {
  background-color: var(--indigo-hover) !important;
}

.function-header-container.nav-bar-sec {
  background-color: #fff;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  max-width: 1228px;
  height: 80px;
  box-shadow: 0 0 1px rgba(22, 19, 74, 0.5), 0 -2px rgba(22, 19, 74, 0.08),
    0 2px 2px rgba(22, 19, 74, 0.05), 0 9px 13px rgba(13, 22, 47, 0.1);
}

.customHoverEffect {
  width: 100%;
  height: 100%;
}

.customHoverEffect:hover {
  background-color: var(
    --indigo-hover
  ); /* Assuming you have a CSS variable for primary.light */
}

.theme-specfic-colored-text {
  color: var(--indigo);
}

.red-colored-text {
  color: var(--red);
}

.yellow-colored-text {
  color: var(--yellow);
}

.sub-header {
  color: var(--dark-purple-50-text);
}

.theme-background-color {
  color: var(--indigo);
}

.theme-white-background-color {
  color: var(--white-bg);
}

.yellow-background-color {
  background-color: var(--yellow);
}

.tab-selected {
  background-color: var(--white) !important;
  margin: 5px !important;
  border-radius: 10px !important;
  color: var(--dark-purple) !important;
}

.tab-default {
  background-color: inherit !important;
  color: var(--dark-purple) !important;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.tab-container {
  overflow: visible !important;
  width: 90% !important; /* Need to make this 100% and remove left and right margin to accomodate the common container */
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 24px !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.tab-container-child {
  background-color: var(--dark-purple-5) !important;
  transform: translateY(-50%) !important;
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}

.typography-custom-css {
  font-family: Inter, sans-serif !important;
}

.typography-custom-css-white-text {
  color: white !important;
}

.tab-container-ai-tools {
  overflow: visible !important;
  width: 90% !important; /* Need to make this 100% and remove left and right margin to accomodate the common container */
  border-radius: 24px !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: none !important;
}

.tab-container-child-ai-tools {
  background-color: var(--dark-purple-5) !important;
  transform: translateY(-50%) !important;
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}

.typography-custom-text {
  font-family: "Oxygen", sans-serif;
  color: white;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
  color: red; /* Change this to your desired color */
}

.theme-specific-background-color {
  background-color: var(--indigo) !important;
}

.dark-purple-background-color {
  background-color: var(--dark-purple) !important;
}

.theme-specific-color {
  color: var(--indigo) !important;
}

.racing-sans-one-regular {
  font-family: "Racing Sans One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.white-bg {
  background-color: var(--white-bg);
}

.css-fnkrly {
  margin: 0px 0px 0.35em !important;
  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  font-size: 1.5625rem !important;
  line-height: 1.235 !important;
  color: rgb(55, 125, 255) !important;
}

.button-shoping {
  background-color: var(--Goldenrod) !important;
  border: 1px solid var(--Goldenrod-hover) !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 9px 13px -5px rgba(229, 165, 32, 0.5),
    /* Darker shade of Goldenrod */ 0 2px 2px 0 rgba(229, 165, 32, 0.5),
    inset 0 -2px 0 0 rgba(229, 165, 32, 0.5),
    inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    /* Light inner shadow */ inset 0 7px 12px 0 rgba(139, 69, 19, 0.5),
    inset 0 7px 12px 0 rgba(139, 69, 19, 0.5) !important; /* Complementary color */
  font-weight: 500;
}

.button-shoping:hover {
  background-color: var(--Goldenrod-hover) !important;
}

.light-green-background {
  background-color: var(--light-green) !important;
}

.green-background {
  background-color: var(--green) !important;
}

.green-color {
  color: var(--green) !important;
}

.light-error-bg {
  background-color: var(--error-bg) !important;
}

.error-main {
  color: var(--error-main) !important;
}

.top-1-bg {
  background-color: var(--palette-primary-lighter) !important;
}

.top-1-text {
  color: var(--palette-green-darker) !important;
}

.top-2-bg {
  background-color: var(--palette-purple-lighter) !important;
}

.top-2-text {
  color: var(--palette-purple-main) !important;
}

.top-3-bg {
  background-color: var(--palette-info-lighter) !important;
}

.top-3-text {
  color: var(--palette-info-main) !important;
}

.top-4-bg {
  background-color: var(--palette-warning-lighter) !important;
}

.top-4-text {
  color: var(--palette-warning-main) !important;
}

.top-5-bg {
  background-color: var(--palette-error-light) !important;
}

.top-5-text {
  color: var(--palette-error-dark) !important;
}

.av-0-background {
  background-color: var(--palette-error-dark) !important;
}

.av-1-background {
  background-color: var(--palette-warning-main) !important;
}

.av-2-background {
  background-color: var(--palette-purple-main) !important;
}

/* Define a class with a linear gradient background from top to bottom */
.white-linear-gradient-background {
  background: linear-gradient(to bottom, #ffffff, #f7faff);
}

.theme-specific-button {
  padding: 1rem 2rem !important;
  border-radius: 14px !important;
  color: var(--indigo) !important;
}

.theme-specific-bg-button {
  background-color: var(--indigo) !important;
  border: 1px solid var(--dark-indigio) !important;
  border-radius: 14px !important;
  padding: 1rem 2rem !important;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple) !important;
  font-weight: 500;
}

.theme-specific-bg-button:hover {
  background-color: var(--indigo-hover) !important;
}

.contact-us-linear-gradient-background {
  background: linear-gradient(to bottom, #d1e9ff, #102b67);
}

.text-decoration-underline {
  text-decoration: underline;
  text-decoration-color: rgba(249, 185, 52, 0.3); /* Set the underline color */
  text-decoration-thickness: 7px; /* Increase the thickness of the underline */
}

/* Define the base styles */
.card-translate-effect {
  transition: transform 0.2s, box-shadow 0.2s !important; /* Smooth transition */
}

/* Define the hover styles */
.card-translate-effect:hover {
  transform: translateY(-4px) !important; /* Lift more on hover */
}

.overlay::after {
  position: absolute;
  content: "";
  width: 20%;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100%;
  background-size: 18px 18px;
  background-image: radial-gradient(
    rgba(47, 106, 217, 0.4) 20%,
    transparent 20%
  );
  opacity: 0.2;
}

.overlay-bottom-right::after {
  position: absolute;
  content: "";
  width: 20%;
  z-index: -1;
  bottom: 0;
  right: 0;
  height: 100%;
  background-size: 18px 18px;
  background-image: radial-gradient(
    rgba(47, 106, 217, 0.4) 20%,
    transparent 20%
  );
  opacity: 0.2;
}

.overlay-diagonal-stripes::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0) 10px,
    rgba(0, 0, 0, 0) 20px
  );
}

.overlay-gradient::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1)
  );
}
.overlay-dotted::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-size: 10px 10px;
  background-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.2) 1px,
    transparent 1px
  );
}

.overlay-zigzag::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: repeating-linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0) 10px,
    rgba(0, 0, 0, 0) 20px
  );
}

.gradient-background {
  background: linear-gradient(45deg, #f9fafb, #e2e8f0);
  background-size: 400% 400%;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.radial-gradient-background {
  background-color: #f9fafb;
  background-image: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 1px,
      transparent 1px
    ),
    radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 25px 25px;
  background-position: 0 0, 12px 12px;
}

.floating-particles-background {
  background: linear-gradient(120deg, #fff, #e2e8f0);
  background-size: cover;
  animation: floatingParticles 4s ease-in-out infinite;
}

@keyframes floatingParticles {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.wavy-border {
  position: relative;
  background: var(--custom-gradient);
  color: var(--white);
  overflow: hidden;
  padding-bottom: 50px; /* Add padding to ensure content is not cut off */
}

.wavy-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px; /* Adjust the height as needed */
  background: inherit;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 100%, 100% 50%);
}

.gradient-text {
  background: linear-gradient(90deg, #00FFFF, #6056FF);
  background-clip: text; /* Standard property */
  -webkit-background-clip: text; /* For WebKit browsers like Chrome & Safari */
  -webkit-text-fill-color: transparent;
  color: transparent; /* Ensures compatibility with all browsers */
}

/* .primary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  text-transform: none;
  box-shadow: 0 4px 14px rgba(99, 102, 241, 0.4);
  background: linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%);
  color: white !important;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primary-button:hover {
  box-shadow: 0 6px 20px rgba(99, 102, 241, 0.6);
  background: linear-gradient(90deg, #5661F0 0%, #7048F5 100%);
}

.primary-button .button-icon {
  margin-right: 8px;
  display: inline-flex;
}
 */


 .primary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1rem;
  text-transform: none;
  box-shadow: 0 6px 15px rgba(99, 102, 241, 0.4);
  background: linear-gradient(90deg, #6366F1 0%, #8B5CF6 100%);
  color: white !important;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.primary-button:hover {
  box-shadow: 0 8px 25px rgba(99, 102, 241, 0.6);
  background: linear-gradient(90deg, #5661F0 0%, #7048F5 100%);
}

/* For the icon spacing */
.primary-button .button-icon {
  margin-right: 8px;
  display: inline-flex;
}

/* The animated sweep effect */
.primary-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.primary-button:hover::after {
  transform: translateX(0);
}

.secondary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;  /* Adjusted padding for better proportions */
  width: 100%;  /* Makes the button take full width as shown in the image */
  border-radius: 30px;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;  /* The button text appears to be uppercase */
  letter-spacing: 0.5px;  /* Added letter spacing for better readability */
  background: #6366F1;
  color: white !important;
  border: none;
  box-shadow: 0 4px 10px rgba(99, 102, 241, 0.3);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.secondary-button:hover {
  background-color: #5661F0;
  box-shadow: 0 6px 15px rgba(99, 102, 241, 0.4);
  color: white !important;
}

/* For the icon spacing */
.secondary-button .button-icon {
  margin-right: 8px;
  display: inline-flex;
}

/* The animated sweep effect */
.secondary-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.secondary-button:hover::after {
  transform: translateX(0);
}

/* Responsive width */
@media (max-width: 600px) {
  .primary-button {
    width: 100%;
  }
}

@media (min-width: 601px) {
  .primary-button {
    min-width: 180px;
  }
}
