.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
.text-content-paragragh {
  line-height: 1.5;
  font-family: Times New Roman, Times, serif;
  color: var(--text-primary);
  letter-spacing: 0.00938em;
  font-size: 1.25rem;
  /* Add any other styles you want */
}

.logo-large {
  position: absolute;
  top: -6%;
  left: -3%;
  z-index: 100;
  width: auto;
  height: 30%;
}
.logo-sml {
  position: fixed;
  top: -3%;
  left: -10%;
  z-index: 100;
  width: auto;
  height: 20%;
}
.logo:hover {
  cursor: pointer;
}

.quote {
  position: relative;
  width: 20vw;
  word-wrap: break-word;
}

.quote::before,
.quote::after {
  color: #fff;
  font-size: 2em;
  position: absolute;
}

.quote::before {
  content: "“";
}

.quote::after {
  content: "”";
}
