/* Button.css */

/* button-primary-nav is for reference */
.button-primary-nav {
  border: 1px solid var(--dark-indigio);
  background-color: var(--indigo);
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple);
  white-space: pre;
  border-radius: 8px;
  flex-flow: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  min-height: 36px;
  max-height: 36px;
  padding: 16px;
  font-weight: 500;
  display: flex;
  position: relative;
}
.button {
  background-color: var(--indigo) !important;
  border: 1px solid var(--dark-indigio) !important;
  padding: 10px 20px !important;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 9px 13px -5px var(--indigo-40-opacity),
    0 2px 2px 0 var(--indigio-25-opacity),
    inset 0 -2px 0 0 var(--dark-indigo-50-opacity),
    inset 0 0 0 1px var(--white-20-opacity), inset 0 7px 12px 0 var(--purple) !important;
  font-weight: 500;
}

.button:hover {
  background-color: var(--indigo-hover) !important;
}

.function-header-container.nav-bar-sec {
  background-color: #fff;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  max-width: 1228px;
  height: 80px;
  box-shadow: 0 0 1px rgba(22, 19, 74, 0.5), 0 -2px rgba(22, 19, 74, 0.08),
    0 2px 2px rgba(22, 19, 74, 0.05), 0 9px 13px rgba(13, 22, 47, 0.1);
}

.customHoverEffect {
  width: 100%;
  height: 100%;
}

.customHoverEffect:hover {
  background-color: var(
    --indigo-hover
  ); /* Assuming you have a CSS variable for primary.light */
}

.theme-specfic-colored-text {
  color: var(--indigo);
}

.sub-header {
  color: var(--dark-purple-50-text);
}

.theme-background-color {
  color: var(--indigo);
}

.tab-selected {
  background-color: var(--white) !important;
  margin: 5px !important;
  border-radius: 10px !important;
  color: var(--dark-purple) !important;
}

.tab-default {
  background-color: inherit !important;
  color: var(--dark-purple) !important;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
}

.tab-container {
  overflow: visible !important;
  width: 90% !important; /* Need to make this 100% and remove left and right margin to accomodate the common container */
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 24px !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.tab-container-child {
  background-color: var(--dark-purple-5) !important;
  transform: translateY(-50%) !important;
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}

.typography-custom-css {
  font-family: "Poppins", sans-serif;
}

.tab-container-ai-tools {
  overflow: visible !important;
  width: 90% !important; /* Need to make this 100% and remove left and right margin to accomodate the common container */
  border-radius: 24px !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: none !important;
}

.tab-container-child-ai-tools {
  background-color: var(--dark-purple-5) !important;
  transform: translateY(-50%) !important;
  width: 90% !important;
  margin-left: 5% !important;
  margin-right: 5% !important;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}

.cross-line {
  position: relative !important;
}

.cross-line::after {
  content: "" !important;
  position: absolute !important;
  top: 50% !important;
  left: 0 !important;
  width: 100% !important;
  height: 2px !important;
  background-color: black !important; /* Adjust color as needed */
  transform: translateY(-50%) !important;
}
